<template>
    <div class="login">
        <div class="banner">
            <img v-if="tcrt == 'false'" src="@/assets/login_banner.png" />
            <img v-if="tcrt == 'true'" src="@/assets/WechatIMG861.jpeg" />
        </div>
        <div class="login-form">
            <van-form @submit="onSubmit">
                <p class="title">手机号</p>
                    <van-field
                        v-model="mobile"
                        center
                        clearable
                        placeholder="请输入手机号"
                        :rules="[{ required: true, message: '请输入手机号' }]"
                        >
                        <template #left-icon class="flex">
                            <img class="safe" src="@/assets/mobile.png" />
                        </template>
                    </van-field>
                <p class="title">验证码</p>
                <van-field
                    v-model="sms"
                    center
                    clearable
                    placeholder="请输入验证码"
                    :rules="[{ required: true, message: '请输入验证码' }]"
                    >
                    <template #left-icon class="flex">
                        <img class="safe" src="@/assets/safe.png" />
                    </template>
                    <template #button>
                        <span class="valid-text" @click="onHandleGetMessage">{{ btnText }}</span>
                    </template>
                </van-field>
                <div>
                    <van-button class="login-btn" round block type="info" native-type="submit">登录</van-button>
                </div>
                <div class="agreement flex">
                    <van-checkbox shape="square" icon-size="16px" v-model="checked"></van-checkbox>
                    <div class="flex-1 padding-left-sm">
                        <span>我已阅读并同意</span>
                        <span class="text-link" @click="onShow(0)">《隐私协议》</span>
                        <span class="text-link" @click="onShow(1)">《注册协议》</span>
                    </div>
                </div>
            </van-form>
        </div>
        <van-popup
            v-model="showFlag"
            round
            closeable
            position="bottom"
            close-icon="close"
            :style="{ height: '70%' }">
            <div class="text-dialog flex flex-column">
                <h2>{{content.title}}</h2>
                <div class="text-content flex-1" v-html="content.content"></div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { onFetchMessage, onLoginOrRegister } from '@/api';
import { contents } from './util';
import { contentspublic3 } from './utilpublic3';
import { Toast } from 'vant';
export default {
    data() {
        return {
            mobile: '',
            sms: '',
            checked: false,
            showFlag: false,
            values: null,
            content: '',
            time: 60,
            btnStatus: true,
            btnText: '获取验证码',
            publicType: null,
            tcrt: 'false'
        }
    },
    created() {
        const token = localStorage.getItem('token');
        this.publicType = localStorage.getItem('publicType');
        this.tcrt = localStorage.getItem('tcrt');
        if (token) {
            this.$router.replace('/personal');
        }
    },
    methods: {
        async onHandleGetMessage() {
            if (!this.btnStatus) {
                return false;
            }
            if (!this.mobile) {
                Toast('请先输入手机号');
                return;
            }
            if (this.mobile.length != 11) {
                Toast('请输入正确的手机号');
                return;
            }
            try {
                const res = await onFetchMessage({
                    userPhone:this.mobile,
                    publicType: this.publicType
                });
                Toast('短信已发送');
                console.log('res', res);
                this.btnStatus = false;
                let timer = setInterval(() => {
                    if (--this.time > 0) {
                        this.btnText = `${this.time}s重新发送`;
                    } else {
                        this.btnText = '获取验证码';
                        clearInterval(timer);
                        this.btnStatus = true;
                        this.time = 60;
                    }
                }, 1000);
            } catch(err) {
                this.btnStatus = true;
                console.log('err', err);
            }
        },
        async onSubmit() {
            if (!this.checked) {
                Toast('请阅读并同意下方协议');
                return false;
            }
            try {
                const params = {
                    userPhone: this.mobile,
                    securityCode: this.sms,
                    publicType: localStorage.getItem('publicType')
                };
                let code = localStorage.getItem('code');
                if (code) {
                    Object.assign(params, {
                        code
                    })
                }
                const res = await onLoginOrRegister(params);
                if (res.code == 200) {
                    localStorage.setItem('token', res.data.token);
                    let url = localStorage.getItem('redirectUrl') || '/personal';
                    this.$router.push(url);
                    localStorage.removeItem('redirectUrl');
                } else {
                    Toast(res.msg);
                }
            } catch(err) {
                console.log('err', err);
            }
        },
        onShow(type) {
            this.showFlag = true;
            if(this.publicType == 3 || this.publicType == 4){
                this.content = contentspublic3[type];
            }else{
                this.content = contents[type];
            }
           
        }
    }
}
</script>
<style scoped lang="less">
.login .login-form {
    margin-top: 30px;
    padding: 0 40px;
}
.login .banner {
    width: 100%;
    margin: 0;
    img {
        width: 100%;
    }
}
.login .logo img {
    width: 100%;
}
.login .title {
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 500;
}
.title {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        .tit-con {
            flex: 1;
            display: flex;
            align-items: center;
            i {
                display: inline-block;
                vertical-align: middle;
                margin-right: 12px;
                width: 4px;
                height: 20px;
                background: #005dcc;
                border-radius: 2px;
            }
            span {
                vertical-align: middle;
                line-height: .26rem;
            }
        }
    }
.van-cell {
    position: relative;
    border: 0.5px solid #e4e4e4;
    border-radius: 30px;
    margin-bottom: 20px;
    line-height: 25px;
}
::v-deep .van-field__left-icon {
    display: flex;
    align-items: center;
}
.safe {
    width: 18px;
}
.valid-text {
    color: rgb(0, 93, 204);
    font-size: 14px;
}
::v-deep .van-field__control {
    color: #666 !important;
}
::v-deep .van-field__error-message {
    position: absolute;
    line-height: 12px;
    bottom: -8px;
}
.login-btn {
    margin: 30px 0 20px;
}
.agreement {
    margin-top: 20px;
    align-items: flex-start;
    font-size: 12px;
    color: #1d1d1d;
}
.agreement .text-link {
    color:rgb(0, 93, 204);
}
.text-dialog {
    height: 100%;
    color: #333;
    h2{
        font-size: 16px;
        color: #333;
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
    }
    .text-content {
        padding: 0 10px 30px;
        font-size: 14px;
        line-height: 22px;
        overflow: auto;
    }
}
::v-deep table {
    width: 100% !important;
}
</style>