import { onWxConfig } from '@/api';

var wx = require('weixin-js-sdk');

export function weixinInit(weixinParam) {
    try {
        console.log(weixinParam);
        onWxConfig({
            publicType: 1,
            urlString: weixinParam.url
        }).then(response => {
            if (response.code === 200) {
                doWxConfigInit(response.data, weixinParam);
            }
        })
       
    } catch(err) {
        console.log('err', err);
    }
}
export function doWxConfigInit(wxConfigParam, weixinParam) {
    wx.config({
        debug: false,
        appId: wxConfigParam.appId,
        timestamp: wxConfigParam.timestamp,
        nonceStr: wxConfigParam.nonceStr,
        signature: wxConfigParam.signature,
        jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'chooseWXPay',
            'scanQRCode'
        ],
    });
    wx.ready(function () {
        wx.updateAppMessageShareData({
            title: weixinParam.title,
            desc: weixinParam.desc,
            link: weixinParam.url,
            imgUrl: weixinParam.imgUrl,
            success: function () {
            }
        })
        wx.updateTimelineShareData({
            title: weixinParam.title,
            link: weixinParam.url,
            imgUrl: weixinParam.desc,
            success: function () {
            }
        })
    });
    wx.error(function(res) {
        if(weixinParam.type == 1){
            location.reload();
        }
        
    });
}